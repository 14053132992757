@tailwind components;
@tailwind utilities;


.login-input label:before {
  width: 0;
  content: '' !important;
}



.fts-transition{
  transition: height 0.3s;
}


.setting-view > .ant-tabs > .ant-tabs-nav > .ant-tabs-nav-wrap{
  padding: 0 20px;
}



.plaid-style > .ant-tabs-content-holder > .ant-tabs-content{
  width: 100%;
  height: 100%;
}
